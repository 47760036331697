import { ComponentPropsWithRef } from "react";

export default function RecordSVG({
  alt,
  title,
  ...props
}: { alt?: string; title?: string } & ComponentPropsWithRef<"svg">) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="5 7 22 18"
      role="image"
      {...props}
    >
      {(alt || title) && <title>{alt || title}</title>}
      <path
        fill="currentColor"
        d="M13.497 20.847c-1.106 0-2.384-.027-3.536-.355-.215-.06-.476-.131-.539-.256-.113-.226.066-.799.173-1.142.033-.104.062-.197.08-.27.132-.508.381-.815.807-.998a3.04 3.04 0 0 0 .278-.143c.154-.085.3-.166.456-.192.144-.023.274-.078.384-.129a.52.52 0 0 1 .204-.029c.081-.002.174-.004.266-.039.48-.187.422-1.205.419-1.248a2.042 2.042 0 0 0-.143-.573c-.091-.248-.228-.375-.36-.498-.064-.061-.132-.123-.199-.205a.429.429 0 0 1-.067-.197c-.021-.109-.044-.234-.146-.334a.57.57 0 0 1-.161-.413c-.018-.21-.011-.415-.005-.619l.007-.306c0-.111.041-.223.085-.342l.046-.129c.023-.072.027-.141.032-.202.005-.071.009-.133.04-.185.224-.368.706-.886 1.08-.886l.018.001a.842.842 0 0 0 .304-.051c.064-.021.12-.04.18-.04.052 0 .102.006.151.011.058.007.116.013.178.013l.471-.015.15.109.182-.007c.475.24.711.398.931.847l.078.153c.094.182.201.388.23.566.018.107.003.242-.012.385-.025.238-.052.484.061.682a7.242 7.242 0 0 0-.15.414l-.037.104a1.266 1.266 0 0 0-.094.395.48.48 0 0 0-.272.282c-.088.209-.19.353-.309.519-.05.069-.101.141-.153.221-.108.164-.068.425-.032.655.014.088.027.171.027.234.007.647.621.769 1.114.867.143.027.279.055.396.093.599.197 1.029.34 1.234.878.162.426.238.884.24 1.442.002.322-.022.389-.326.502-1.151.43-2.537.43-3.761.43zM19 24c-.53 0-.9-.261-.9-.45 0-.051.025-.126.075-.177.151-.152.425-.262.774-.262.607 0 1.051-.506 1.051-1.112v-3c0-.496.503-.999.999-.999h2.999c.606 0 1.1-.444 1.1-1.05.001-.53.264-.875.451-.875.189-.001.451.394.451.924V18c0 .52-.361 1.269-.729 1.637l-3.61 3.636c-.368.367-1.142.727-1.662.727H19z"
      ></path>
      <path
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
        d="M20.707 23.293C20.318 23.682 19.55 24 19 24H7c-.55 0-1-.45-1-1V9c0-.55.45-1 1-1h18c.55 0 1 .45 1 1v8c0 .55-.318 1.318-.707 1.707l-4.586 4.586z"
      ></path>
      <g>
        <path
          fill="currentColor"
          d="M18.11 14.89v-.78h4.779v.779H18.11v.001zm0-2v-.78h4.779v.779H18.11v.001z"
        ></path>
      </g>
    </svg>
  );
}
